// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const Rails = require("@rails/ujs")
Rails.start()
window.Rails = Rails
// require("turbolinks").start()
import jquery, { isWindow } from 'jquery'
import 'jquery.repeater'
window.jQuery = jquery
window.$ = jquery
import '../lib/slick.min.js'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'select2';
import 'select2/dist/css/select2.css';
import Inputmask from "inputmask"
window.Inputmask = Inputmask
import Holder from "holderjs"
window.Holder = Holder
import FileAPI from 'fileapi'
import flatpickr from "flatpickr"
import 'flatpickr/dist/flatpickr.min.css'
import { Russian } from "flatpickr/dist/l10n/ru.js"
window.flatpickr = flatpickr
window.flatpickrRussian = Russian
const Vue = require('vue/dist/vue')
window.Vue = Vue
//import Rellax from '../lib/rellax.min.js'
//window.Rellax = Rellax
import '../lib/ismobile.min.js'
import Mustache from '../lib/mustache'
window.Mustache = Mustache
// import '../lib/controls.js'
import '../styles/application.css'
import '../styles/bootstrap_custom.css'
//import ymaps from 'ymaps'
//window.ymaps = ymaps
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
import Cropper from 'cropperjs';
window.Cropper = Cropper
import 'cropperjs/dist/cropper.css';
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import 'social-likes';
import 'social-likes/dist/social-likes_flat.css';  // Flat skin
//import 'social-likes-next';
//import 'social-likes-next/lib/social-likes_flat.css';  // Flat skin
import Highcharts from "highcharts/highcharts.js";
window.Highcharts = Highcharts
import Highmaps from "highcharts/highmaps.js";
window.Highmaps = Highmaps
